import {defineStore} from "pinia";
import {ref} from "vue";

import {SHOP_TENANT_ID} from '@/environment'
import {supabaseClient} from '@/supabase'
import {GIFT_CARDS_TENANT_ID, RECHARGES_NAUTA_TENANT_ID, RECHARGES_TENANT_ID, TENANT_ID} from "../environment";

export const useUserStore = defineStore('user', () => {
    const isLogued = ref(false)
    const userId = ref(null)
    const userData = ref(null)
    const isSessionLoading = ref(false)

    /* Auto refresh token using the supabase event */
    supabaseClient.auth.onAuthStateChange((event, session) => {
        console.log('event:', event, session)
        if (event === 'TOKEN_REFRESHED') {
            isSessionLoading.value = true
            supabaseClient
                .functions
                .invoke(
                    'refresh_token',
                    {body: {tenants: [SHOP_TENANT_ID, RECHARGES_TENANT_ID, GIFT_CARDS_TENANT_ID, RECHARGES_NAUTA_TENANT_ID, TENANT_ID]}}
                ).then(({data, error}) => {
                if (error) {
                    console.log('OCURRIO UN ERROR:', error)
                }
                supabaseClient.auth.setSession({access_token: data.token, refresh_token: session.refresh_token})
                isSessionLoading.value = false
            })
        }
    })

    async function isLoadedProfileData() {
        if (isLogued.value) {
            return true
        } else {
            const {error, data} = await supabaseClient.auth.getUser()
            console.log('xxxxx', error, data)
            if (error || !data.user)
                return false

            const response = await getUserProfile(data.user.id)
            if (response.error || !response.data.was_verified) {
                return false
            }

            userData.value = response.data
            userId.value = data.user.id
            isLogued.value = true
            return true
        }
    }

    function getUserProfile(id) {
        return supabaseClient.from('users').select('*, gift_users_settings(*)').eq('id', id).single()
    }

    async function login(authObj) {

        const {error: errorSignIn, data: loginData} = await supabaseClient.auth.signInWithPassword(authObj)
        if (errorSignIn) {
            return {error: errorSignIn}
        }
        return await endLogin(loginData)
    }

    async function loginWithGoogle() {
        const {error} = await supabaseClient.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: 'https://janetstravelguide.com'
            }
        })
        if (error) {
            return {error}
        }
    }

    async function endLogin(loginData) {
        const {
            error: errorRefreshingTokens,
            data
        } = await supabaseClient.functions.invoke('refresh_token', {body: {tenants: [SHOP_TENANT_ID, RECHARGES_TENANT_ID, GIFT_CARDS_TENANT_ID, RECHARGES_NAUTA_TENANT_ID, TENANT_ID]}})

        if (errorRefreshingTokens) {
            return {errorRefreshingTokens}
        }

        await supabaseClient.auth.setSession({access_token: data.token, refresh_token: loginData.session.refresh_token})

        userId.value = loginData.user.id
        const {error: errorGettingProfile, data: dataFromProfile} = await getUserProfile(userId.value)


        if (errorGettingProfile) {
            console.error(errorGettingProfile)
            return {dataFromProfile, errorGettingProfile}
        } else if (!dataFromProfile.was_verified) {
            console.error('The user is not verified!')
            return {error: {message: 'The user is not verified!'}}
        }
        userData.value = dataFromProfile
        isLogued.value = true
        return {dataFromProfile, errorGettingProfile}
    }

    async function register(regisObj) {
        // TODO tambien agregar el tenant_id ?
        return await supabaseClient.functions.invoke('register_user', {body: {...regisObj, tenant_id: SHOP_TENANT_ID}})
    }

    async function confirmUserRegister({code, userId}) {
        return await supabaseClient.functions.invoke(
            'confirm_user_register', {
                body: {
                    code: code,
                    user_id: userId
                }
            })
    }

    async function referal_by({code, userId}) {
        return await supabaseClient.functions.invoke(
            'referral_by', {
                body: {
                    referral_code: code,
                    user_id: userId
                }
            })
    }


    async function changePassword({old_password, password}) {
        return await supabaseClient.functions.invoke('update_password', {
            body: {
                old_password: old_password,
                password: password,
                user_id: userId.value
            }
        })
    }

    async function logout() {
        await supabaseClient.auth.signOut();
        isLogued.value = false;
        userId.value = null;
        userData.value = null;
    }

    return {
        isLogued,
        isSessionLoading,
        userData,
        userId,
        isLoadedProfileData,

        register,
        referal_by,
        confirmUserRegister,

        login,
        loginWithGoogle,
        endLogin,
        logout,
        changePassword
    }
});
