

export const environment = {
  // SUPABASE_URL: 'https://api.dara-services.org',
  // ANON_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVjYndndnZhdm5pdG5qYWVmaHljIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg2NzkzNjYsImV4cCI6MjAxNDI1NTM2Nn0.JIzroGt5_-twUtdq8ty7Wo7lmkShPJRe2UQRi7yLRBI'

  SUPABASE_URL: 'https://okryxlfewrzzgspgvedt.supabase.co',
  ANON_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9rcnl4bGZld3J6emdzcGd2ZWR0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE1MDI4NjgsImV4cCI6MjAwNzA3ODg2OH0.oyNGdCdlQjEOss8SgCJvfMjgt2Aj9WDyxzhlFmmyjO0'
}

//  --- production
export const TENANT_ID = 'e2bebb32-450e-4112-849b-ef36ff704b3d'
export const SHOP_TENANT_ID = '5a8eb371-e8cf-4063-b778-fa1dbbef310a'
export const GIFT_CARDS_TENANT_ID = 'e0ba7289-35cc-47c5-900c-ae7a11d6318f'
export const RECHARGES_TENANT_ID = '4da43ffc-9df6-4eba-b6b2-7ee0feb6b07e'
export const RECHARGES_NAUTA_TENANT_ID = '7009640b-c21d-47cf-b43f-e77978a33be9'

// --- develop
// export const SHOP_TENANT_ID = '7b3e6823-7ce4-4cde-a79d-7f3c80c71467'
// export const TENANT_ID = 'f1417d5e-2579-49a7-b61c-c6dee2448002'
// export const GIFT_CARDS_TENANT_ID = 'a833c33f-ed15-4c3e-9de2-b427dc915ce4'
// export const RECHARGES_TENANT_ID = 'e660e730-8787-46e3-9a3a-c247651e1329'
// export const RECHARGES_NAUTA_TENANT_ID = '8d532541-29fe-443b-9d87-b9da30479e0d'


export const CHAPORT_ID = '65c52f90119e5b03160ef70c'

export const SENTRY_ENV = 'production'
